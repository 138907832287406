<template>
	<li class="appointment" :data-id="app.id">
		<div class="appointment-hour">
			<span class="hour">{{app.startHour}}</span>
			<br>
			{{app.endHour}}
			<br>{{app.duration}}
		</div>
		<div class="appointment-content">
			<span class="appointment-text">
				<span>
					<span data-field="title" class="appointment-titles">{{ app.title }}</span>
					<br/><b>{{ $t('common.for') }} </b>
					<i v-if="app.user && !!app.user.unregistration">[{{ $t('common.accdel') }}]</i>
					<router-link v-else-if="app.user" :to="'/user?id=' + app.user.id">{{ app.user.fname + ' ' + app.user.lname }}</router-link>
					<i v-else-if="app.userid > 0">[{{ app.userid }}]</i>
					<i v-else> {{ $tc('common.anonymous', 1) }}</i>
					<br/><b>{{ $t('common.with') }} </b>
					<router-link v-if="app.worker.id" :to="'/worker/' + app.worker.id">{{ app.worker.name }}</router-link>
					<span v-else :to="'/worker/' + app.worker.id"><i>[{{ app.worker.name }}]</i></span>
				</span>
				<span v-if="app.message" class="message">
					« {{ app.message.slice(0, 256) + (app.message.length > 256 ? '...' : '') }} »
				</span>
			</span>
			<div class="appointment-tools">
				<div class="price-and-message">
					<div v-if="app.price && app.price > 0" class="price">{{ app.price }}€</div>
				</div>
				<div class="appointment-buttons">
					<CollapsableButtons v-if="app.status !== 'finished' && app.status !== 'canceled' && app.status !== 'absent'" :data-debug="app.status">
						<template v-slot:main>
							<StatusButton name="finish" v-if="$store.state.salon.mode=='solo' || app.status=='ongoing'" @click="$emit('finishAppointment', { id: app.id, callback: $event })" type="outline-success" alert="modal">{{$t("index.fini")}}</StatusButton>
							<StatusButton name="begin" v-else-if="app.status==null || app.status==''" @click="changeStatus(app.id, 'ongoing', $event)" type="outline-success" alert="modal">{{$t("index.began")}}</StatusButton>
						</template>
						<template v-slot:collapsable>
							<StatusButton name="missing" v-if="app.status==null || app.status==''" @click="$emit('absentAppointment', { id: app.id, callback: $event })" class="mobile" type="outline-gray" alert="modal">{{$t("index.missing")}}</StatusButton>
							<StatusButton name="cancel" v-if="app.status==null || app.status==''" @click="$emit('cancelAppointment', { id: app.id, callback: $event })" class="mobile" type="outline-danger" alert="modal">{{$t("common.cancel")}}</StatusButton>
							<StatusButton name="not-started" v-if="app.status == 'ongoing'" @click="changeStatus(app.id, '', $event)" class="mobile btn btn-outline-danger" alert="modal">{{$t("index.notstarted")}}</StatusButton>
						</template>
					</CollapsableButtons>
					<CollapsableButtons v-if="app.status !== 'finished' && app.status !== 'canceled' && app.status !== 'absent'" class="desktop" :data-debug="app.status" :always="true">
						<template v-slot:main>
							<StatusButton name="cancel" v-if="app.status==null || app.status==''" @click="$emit('cancelAppointment', { id: app.id, callback: $event })" type="outline-danger" alert="modal">{{$t("common.cancel")}}</StatusButton>
							<StatusButton name="not-started" v-if="app.status == 'ongoing'" @click="changeStatus(app.id, '', $event)" type="outline-danger" alert="modal">{{$t("index.notstarted")}}</StatusButton>
						</template>
						<template v-slot:collapsable>
							<StatusButton name="missing" v-if="app.status==null || app.status==''" @click="$emit('absentAppointment', { id: app.id, callback: $event })" type="outline-gray" alert="modal">{{$t("index.missing")}}</StatusButton>
						</template>
					</CollapsableButtons>
					<div v-if="app.status && app.status.toLowerCase().indexOf('absent') >= 0">
						{{ $t('index.missing') }}
					</div>
					<div v-if="app.status && app.status.toLowerCase().indexOf('canceled') >= 0">
						{{ $t('common.canceled') }}
					</div>
				</div>
			</div>
		</div>
	</li>
</template>


<script>
	import StatusButton from "../../components/StatusButton.vue"
	import CollapsableButtons from "../../components/CollapsableButtons.vue"

	export default {
		components: {
			StatusButton,
			CollapsableButtons
		},
		props: [
			'app'
		],
		data() {
			return {
			}
		},
		methods: {
			changeStatus(id, status, callback) {
				this.$api.appointment.patch(id, { status: status } ).then( response => {
					callback( true )
				}).catch( error => {
					console.log( error )
					callback( false, error.response.data.error )
				})
			},
			setUser( user ) {
				this.app.user = user
			}
		}
	}
</script>

<style scoped lang="scss" src="../../css/pages/appointments.scss"/>
