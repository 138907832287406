<template>
	<div class="stock-selector">
		<template v-if="stock">
			<slot name="before"></slot>
		</template>
		<div :class="stock ? 'btn dependency' : 'btn-new-dependency'">
			<template v-if="stock">
				{{ stock.manufacturer }}
				{{ stock.name }}
				<template v-if="stock.reference">
					{{ '(' + $t('common.ref') + stock.reference + ')' }}
				</template>
				<span class="dep-remove" @click="$emit( 'remove', stock )">
					<i class="fas fa-times-circle"></i>
				</span>
			</template>
			<template v-else>
				<Popper
					class="dropdown d-block"
					trigger="clickToToggle"
					:options="{ placement: 'bottom', modifiers: { offset: { offset: '0,-2.5rem' } } }"
					:visible-arrow="false"
					:force-show="forceShow"
					ref="selector"
					@show="/*$nextTick( () => { $refs.searchInput.focus() })*/"
				>
					<div class="dropdown-menu">
						<div>
							<input ref="searchInput" class="dropdown-searchbox form-control" placeholder="Rechercher..." type="text" :readonly="( window.innerWidth < 768 ) ? firstFocus : undefined" @click="firstFocus = undefined" @input="search( $event.target.value )">
							<i class="fas fa-backspace" @click="$refs.searchInput.value = ''; search( '' )"></i>
						</div>
						<table class="dropdown-choices">
							<thead>
								<tr>
									<th>{{$t("stocks.refcode")}}</th>
									<th>{{$t("common.brand")}}</th>
									<th>{{$t("common.name")}}</th>
									<th>{{$t("stocks.stocks")}}</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="stock in stocks"
									v-if="!hide.includes(stock.id)" 
									class="dropdown-item"
									type="button"
									@click="$refs.selector.doClose(); $emit( 'selected', JSON.parse( JSON.stringify( stock ) ) ); $refs.searchInput.value = ''; search( '' )"
								>
									<td>{{ stock.reference }}</td>
									<td>{{ stock.manufacturer }}</td>
									<td>{{ stock.name }}</td>
									<td>{{ stock.stocks }}</td>
								</tr>
								<tr class="empty">
									<td class="empty-hint" v-if="!stocks || !stocks.filter(s => !hide.includes(s.id)).length" colspan="4">
										{{ $t('stocks.empty') }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<button
						ref="addButton"
						slot="reference"
						id="category"
						aria-expanded="false"
						aria-haspopup="true"
						class="btn btn-secondary dropdown-toggle"
						data-flip="false"
						data-toggle="dropdown"
						name="btn-category"
						type="button"
					>
						Ajouter...
					</button>
				</Popper>
			</template>
		</div>
		<template v-if="stock">
			<slot/>
		</template>
		<template v-if="stock">
			<slot name="after"></slot>
		</template>
	</div>
</template>

<script>
	import dayjs from 'dayjs'
	import Popper from 'vue-popperjs'

	export default {
		components: {
			Popper
		},
		props: {
			forceShow: { type: Boolean, default: undefined },
			stock: { type: Object, default: null },
			hide: { type: Array, default: () => [] },
		},
		data() {
			return {
				window: window,
				stocks: null,
				firstFocus: "readonly"
			}
		},
		methods: {
			sort( stocks, ids ) {
				this.$api.stockshistory.get( { "stockid[in]": ids, "date[gt]": dayjs(new Date().addDays( -30 )).format("YYYY-MM-DD"), "{count}": "stockid", "{group}": "stockid" } ).then( response => {
					let counts = {}
					for ( let i = 0; i < response.data.length; i++ ) {
						counts[parseInt(response.data[i].stockid)] = parseInt(response.data[i].count)
					}
					this.stocks = stocks.sort( ( a, b ) => ( counts[b.id] || 0 ) - ( counts[a.id] || 0 ) )
				}).catch( error => {
					console.log( error )
				})
			},
			search( filter ) {
				const searchIn = [
					"reference",
					"manufacturer",
					"name",
					"stocks"
				]
				let found = []
				let foundids = []
				filter = filter.toLowerCase()
				for ( var i = 0; i < this.$store.state.stocks.length; i++ ) {
					let stock = this.$store.state.stocks[i]
					let matches = false
					searchIn.forEach( field => {
						if ( ( "" + stock[field] ).toLowerCase().indexOf( filter ) >= 0 ) {
							matches = true
						}
					})
					if ( matches ) {
						found.push( stock )
						foundids.push( stock.id )
					}
				}
			//	this.stocks = found.sort( ( a, b ) => ( a.used || 0 ) - ( b.used || 0 ) )

				this.sort( found, foundids )
			}
		},
		mounted() {
			this.sort( this.$store.state.stocks )
		},
		beforeDestroy() {
		}
	}
</script>

