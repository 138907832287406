<template>
	<div :class="'collapsable-buttons' + ( dropVisible ? ' visible' : '' ) + ( always ? ' always' : '' )">
		<slot name="main"/>
		<div data-collapse-button v-if="$slots.collapsable" ref="toggler" class="btn btn-outline-gray drop-toggler" @click="dropVisible = !dropVisible">
			<i ref="icon" class="fas fa-sort-down"></i>
		</div>
		<div v-if="$slots.collapsable" class="dropdown-choices">
			<slot name="collapsable"/>
		</div>
	</div>
</template>


<script>
	export default {
		props: [
			"always"
		],
		data() {
			return {
				dropVisible: false
			}
		},
		methods: {
			documentClick( event ) {
				if ( event.target != this.$refs.toggler && event.target != this.$refs.icon ) {
					this.dropVisible = false;
				}
			}
		},
		mounted() {
			document.removeEventListener( 'click', this.documentClick );
			document.addEventListener( 'click', this.documentClick );
		},
		beforeDestroy() {
			document.removeEventListener( 'click', this.documentClick );
		}
	}
</script>
