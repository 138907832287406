<template>
	<div>
		<div v-if="/*TODO: use_logo*/false" style="display: flex;"><img class="hairdresser" src="/img/logo.png" style="margin: 1rem auto 0 auto;width: 26rem;"></div>
		<h1 v-else class="hairdresser" :class="{ fancy: $store.state.domain == 'esthetics' }">{{ $store.state.domain == "coachs" ? "@" : "" }}{{ $store.state.salon.name }}</h1>
		<h1 class="title-mobile title-search" >{{ $t("titles.dashboard") }}</h1>
		<div id="base">
			<div class="container">
				<div class="row">
					<div class="col-sm-6 col-md-3">
						<div class="card border-left-primary card-body color-infocard" @click="infos.appointments.day.total > 0 && (appointmentsHistory = appointments)">
							<div class="card-title text-primary">{{ $t("common.validatetaken") }}</div>
							<div class="card-text">{{ infos.appointments.day.validated }} / {{ infos.appointments.day.total }}</div>
						</div>
					</div>
					<div class="col-sm-6 col-md-3">
						<div class="card border-left-secondary card-body color-infocard" @click="infos.appointments.month > 0 && (appointmentsHistory = 'month')">
							<div class="card-title text-secondary">{{ $t("common.appmonth") }}</div>
							<div class="card-text">{{ infos.appointments.month }}</div>
						</div>
					</div>
					<div class="col-sm-6 col-md-3">
						<div class="card border-left-tertiary card-body color-infocard" @click="infos.appointments.year > 0 && (appointmentsHistory = 'year')">
							<div class="card-title text-tertiary">{{ $t("common.appyear") }}</div>
							<div class="card-text">{{ infos.appointments.year }}</div>
						</div>
					</div>
					<div class="col-sm-6 col-md-3">
						<router-link to="/userslist" class="card border-left-quaternary card-body color-infocard">
							<div class="card-title text-quaternary">{{ $t("common.registered") }}</div>
							<div v-if="infos.totalAppClients > 0" class="card-text">
								{{ infos.totalClients }}<i class="fas fa-user apptext"></i> / {{ infos.totalAppClients }}<i class="fas fa-mobile-alt apptext"></i>
							</div>
							<div v-else class="card-text">
								{{ infos.totalClients }}
							</div>
						</router-link>
					</div>
				</div>
				<div v-if="$store.state.salon.mode == 'multi'" class="row block-card">
					<div class="col-md-6">
						<div class="card">
							<h2 class="card-header">{{ $t("common.nextappoint") }}</h2>
							<div v-if="appointments.filter(app => app.status == null || app.status.length == 0).length" class="card-body card-pad">
								<ul id="next-appointments">
									<IndexAppointment
										v-for="app in appointments.filter(app => app.status == null || app.status.length == 0)"
										:key="app.id"
										:ref="'app_' + app.id"
										:app="app"
										@cancelAppointment="cancelAppointment($event)"
										@absentAppointment="absentAppointment = $event"
									/>
								</ul>
							</div>
							<div v-else class="empty-hint">
								{{ $t('appointments.nonenext') }}
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card">
							<h2 class="card-header">{{ $t("common.appinprogress") }}</h2>
							<div v-if="appointments.filter(app => app.status != null && app.status.search('ongoing') >= 0).length" class="card-body card-pad">
								<ul id="ongoing-appointments">
									<IndexAppointment
										v-for="app in appointments.filter(app => app.status != null && app.status.search('ongoing') >= 0)"
										:key="app.id"
										:ref="'app_' + app.id"
										:app="app"
										@finishAppointment="finishAppointment($event)"
									/>
								</ul>
							</div>
							<div v-else class="empty-hint">
								{{ $t('appointments.nonecurrent') }}
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="$store.state.salon.mode == 'solo'" class="row block-card">
					<div class="col-md-6">
						<div class="card">
							<h2 class="card-header">{{ $t("common.nextappoint") }}</h2>
							<div v-if="appointments.filter(app => app.status == null || app.status.length == 0 || app.status.search('ongoing') >= 0).length" class="card-body card-pad">
								<ul id="ongoing-appointments">
									<IndexAppointment
										v-for="app in appointments.filter(app => app.status == null || app.status.length == 0 || app.status.search('ongoing') >= 0)"
										:key="app.id"
										:ref="'app_' + app.id"
										:app="app"
										@finishAppointment="finishAppointment($event)"
										@cancelAppointment="cancelAppointment($event)"
										@absentAppointment="absentAppointment = $event"
									/>
								</ul>
							</div>
							<div v-else class="empty-hint">
								{{ $t('appointments.nonenext') }}
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div id="old-appointments" class="card">
							<h2 class="card-header">{{ $t("common.lactivities") }}</h2>
							<div v-if="appointments.filter(app => app.status != null && (app.status.search('finished') >= 0 || app.status.search('cancel') >= 0 || app.status.search('absent') >= 0)).reverse().slice(0, 10).length" class="card-body card-pad">
								<ul id="finished-appointments">
									<IndexAppointment
										v-for="app in appointments.filter(app => app.status != null && (app.status.search('finished') >= 0 || app.status.search('cancel') >= 0 || app.status.search('absent') >= 0)).reverse().slice(0, 10)"
										:key="app.id"
										:ref="'app_' + app.id"
										:app="app"/>
								</ul>
							</div>
							<div v-else class="empty-hint">
								{{ $t('appointments.nonelast') }}
							</div>
						</div>
					</div>
				</div>
				<div class="row block-card">
					<div :class="'col-md-' + ( ( !['premium','multi'].includes($store.state.salon.subscription) && $store.state.worker.admin ) ? '6' : '12' )" v-if="$store.state.salon.mode == 'multi'">
						<div id="old-appointments" class="card">
							<h2 class="card-header">{{ $t("common.lactivities") }}</h2>
							<div v-if="appointments.filter(app => app.status != null && (app.status.search('finished') >= 0 || app.status.search('cancel') >= 0 || app.status.search('absent') >= 0)).reverse().slice(0, 10).length" class="card-body card-pad">
								<ul id="finished-appointments">
									<IndexAppointment
										v-for="app in appointments.filter(app => app.status != null && (app.status.search('finished') >= 0 || app.status.search('cancel') >= 0 || app.status.search('absent') >= 0)).reverse().slice(0, 10)"
										:key="app.id"
										:ref="'app_' + app.id"
										:app="app"/>
								</ul>
							</div>
							<div v-else class="empty-hint">
								{{ $t('appointments.nonelast') }}
							</div>
						</div>
					</div>
				<!--/div-->
				<!--div class="row"-->
					<div v-if="$store.state.worker.admin/* && ['premium','multi'].includes($store.state.salon.subscription)*/" class="col-md-6">
						<div id="avis" class="card">
							<h2 class="card-header">{{ $t("index.newreviews") }}</h2>
							<div v-if="review" class="card-body">
								<div class="buttons">
									<button id="prev-button" class="btn btn-primary" @click="reviewPrev();" type="button" :disabled="noReviewPrev">
										<i class="fas fa-angle-left"></i>
									</button>
									<div id="review-content">
										<div class="review-date review-class">{{review.dateString}} </div>
										<div class="review-bulle">
											<div class="review-text review-class">« {{review.text}} »</div>
											<div class="review-user-fname review-class">
												<router-link v-if="review.user" :to="'user?id=' + review.user.id">{{ review.user.fname }} {{ review.user.lname }}</router-link>
												<i v-else style="font-weight: normal;">[{{ $t('common.accdel') }}]</i>
											</div>
										</div>
										<!--div v-if="$store.state.worker.admin" class="checkbox checkbox-primary see-or-not review-class">
											<input id="see-or-not" class=" styled d-none" name="see-or-not" type="checkbox" v-model="review.front_page" @click="reviewShowFrontPage( $event, review.id )">
											<label class="d-inline-block" for="see-or-not">
												<div id="reviews-index-title" class="checkbox-text">{{ $t('common.showonapp') }}</div>
											</label>
										</div>
										<StatusButton v-if="$store.state.worker.admin" type="primary" class="remove-review" @click="deleteReviewModalCallback = $event; showDeleteReviewID = review.id">
											<i class="fas fa-trash-alt"></i>
										</StatusButton-->
										<button v-if="$store.state.worker.admin && review.date && dayjs(review.date).isAfter(dayjs().add(-7, 'day')) && !review.dispute_response" :disabled="!!review.dispute" class="btn btn-primary remove-review" @click="disputeReview = review">
											<i v-if="!!review.dispute" class="far fa-hourglass"></i>
											<template v-else>
												{{ $t('common.dispute') }}
											</template>
										</button>
									</div>
									<button id="next-button" class="btn btn-primary" @click="reviewNext();" type="button" :disabled="noReviewNext">
										<i class="fas fa-angle-right"></i>
									</button>
								</div>
							</div>
							<div v-else class="empty-hint">
								{{ $t('reviews.none') }}
							</div>
						</div>
					<!--	<div id="" class="card">
							<h2 class="card-header">{{ $t("common.challenge") }}</h2>
							<div class="card-body">
								<div></div>
							</div>
						</div> -->
					</div>
					<div v-if="$store.state.worker.admin" class="col-md-6">
						<div id="pub" class="card">
							<h2 class="card-header">{{ $t("index.promote") }}</h2>
							<div id="produits-wrapper" class="card-body">
								<form @submit.prevent class="produits">
									<div v-if="editSingleProduct" class="form-group input-group" :class="{ required: missingProductTitle && !products[0].title }">
										<input class="form-control" name="title" placeholder="Titre" type="text" v-model="products[0].title">
									</div>
									<div v-else-if="products[0].title" class="produits-title content-text">{{ products[0].title }}</div>
									<div>
										<div class="espace-texte">
											<div v-if="editSingleProduct" class="form-group input-group">
												<textarea class="form-control" name="content" placeholder="Description" v-model="products[0].text"></textarea>
											</div>
											<div v-else class="produits-text content-text">{{ products[0].text }}</div>
											<div v-if="editSingleProduct" class="form-group input-group" :class="{ required: missingProductPrice && !products[0].price }">
												<input class="form-control" name="price" :placeholder="$t('common.price')" type="text" v-model="products[0].price">
											</div>
											<div v-else-if="products[0].price" class="produits-price content-text">{{ products[0].price }}</div>
										</div>
										<div v-if="editSingleProduct" class="form-group input-group" :class="{ required: missingProductImage && !editSingleProductImage }">
											<input ref="singleProductBrowse" class="form-control d-none" name="photo" type="file" @change="editSingleProductImage = $event.target.files[0].name">
											<input id="photo-uri" class="form-control" :placeholder="$t('common.unchanged')" type="text" v-model="editSingleProductImage">
											<div class="input-group-append">
												<button id="browse" class="input-group-text btn btn-secondary" @click="$refs.singleProductBrowse.click()">{{$t("common.browse")}}</button>
											</div>
										</div>
										<div v-else class="cadre-image content-text">
											<img v-show="products[0].image" alt="" :src="products[0].image">
										</div>
									</div>
								</form>
								<div id="productButtons" v-if="editSingleProduct">
									<StatusButton v-if="products[0].id" id="deleteProduct" type="danger" alert="modal" @click="showDeleteProductConfirm = $event"><i class="fas fa-trash"></i></StatusButton>
									<StatusButton id="validateProduct" type="primary" alert="modal" @click="saveSingleProduct"><i class="fas fa-check"></i></StatusButton>
									<button id="cancelProduct" class="btn btn-gray" @click="cancelSaveSingleProduct">{{ $t('common.cancel') }}</button>
								</div>
								<button v-else-if="$store.state.worker.admin" id="modify" class="fas fa-edit btn btn-primary" @click="editSingleProduct = true"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Confirm
			v-if="showDeleteAppModal"
			:title="$t('common.annulation')"
			@close="showDeleteData.callback(-1); showDeleteAppModal = null"
			@confirm="changeStatus(showDeleteData.id, 'canceled', showDeleteData.callback); showDeleteAppModal = null"
		>
			{{ $t('index.surecancel') }}
		</Confirm>
		<Confirm
			v-if="showDeleteProductConfirm"
			:title="$t('common.annulation')"
			@close="showDeleteProductConfirm = null"
			@confirm="deleteSingleProduct(showDeleteProductConfirm); showDeleteProductConfirm = null"
		>
			{{ $t('index.sureremoveproduct') }}
		</Confirm>
		<DisputeReview v-if="disputeReview" @close="disputeReview=null" :review="disputeReview">
		</DisputeReview>
		<Confirm v-if="absentAppointment" @close="absentAppointment = null" @confirm="clientAbsent()">
			{{ $t('index.confirmabsent') }}
		</Confirm>
		<FinalizeAppointment v-if="finalizeAppointmentData" name="finalizeAppointment" @confirm="finishWithStocks" @close="finalizeAppointmentData.callback(-1); finalizeAppointmentData=null">
		</FinalizeAppointment>
		<AppointmentsHistory v-if="appointmentsHistory" :mode="appointmentsHistory" @close="appointmentsHistory = null"/>
	</div>
</template>


<script>
	import axios from 'axios'
	import dayjs from 'dayjs'
	import StatusButton from "../components/StatusButton.vue"
	import StockButton from "../components/StockButton.vue"
	import Modal from "../components/Modal.vue"
	import Confirm from '../modals/Confirm.vue'
	import FinalizeAppointment from '../modals/FinalizeAppointment.vue'
	import AppointmentsHistory from '../modals/AppointmentsHistory.vue'
	import DisputeReview from '../modals/DisputeReview.vue'
	import IndexAppointment from './index/Appointment.vue'


	export default {
		components: {
			StatusButton,
			Modal,
			Confirm,
			FinalizeAppointment,
			AppointmentsHistory,
			DisputeReview,
			IndexAppointment
		},
		data() {
			return {
				dayjs,
				review: null,
				infos: {
					appointments: {
						day: { validated: 0, total: 0 },
						month: 0,
						year: 0
					},
					totalClients: 0,
					totalAppClients: 0
				},
				appointments: [],
				products: [{ title: '', price: '', text: '', image: '', alt: '', order: 0 }],
				missingProductTitle: false,
				missingProductPrice: false,
				missingProductImage: false,
				editSingleProduct: false,
				editSingleProductImage: "",
				showDeleteAppModal: false,
				showDeleteData: -1,
				disputeReview: null,
				absentAppointment: null,
				finalizeAppointmentData: null,
				appointmentsHistory: null,
				noReviewPrev: true,
				noReviewNext: true,
				showDeleteProductConfirm: null
			}
		},
		methods: {
			async setCurrentReview( review ) {
				if ( review != null ) {
					review.dateString = dayjs(review.date).format("D MMM YYYY HH:mm")
				//	review.user = ( await this.$store.getters.getUser( review.userid ) ).data[0]
					review.user = ( await this.$store.getters.getOnlineUser( review.userid ) ).data[0]
					this.review = review
				}
			},
			reviewPrev() {
				this.$api.reviews.get({"{max}":2, "{sort}":"desc:id", "id[lt]":this.review.id}).then( response => {
					this.setCurrentReview( response.data[0] )
					this.noReviewNext = false
					this.noReviewPrev = !response.data[1]
				}).catch(error => console.log(error))
			},
			reviewNext() {
				this.$api.reviews.get({"{max}":2, "{sort}":"asc:id", "id[gt]":this.review.id}).then( response => {
					this.setCurrentReview( response.data[0] )
					this.noReviewNext = !response.data[1]
					this.noReviewPrev = false
				}).catch(error => console.log(error))
			},
			reviewShowFrontPage( event, id ) {
				this.$api.review.patch( id, { front_page: event.target.checked } ).then( response => {
				}).catch( error => console.log( error ) )
			},
			cancelAppointment( data ) {
				this.showDeleteAppModal = true
				this.showDeleteData = data
			},
			finishAppointment( data ) {
				const appointment = this.appointments.find( app => app.id == data.id )
				if ( appointment ) {
					let usesStock = false
					if ( this.$store.getters.salonHasStocks() ) {
						for ( let i = 0; i < appointment.services.length; i++ ) {
							if ( this.$store.getters.getService( appointment.services[i] ).uses_stock ) {
								usesStock = true
								break
							}
						}
					}
					if ( usesStock ) {
						data.appointment = appointment
						this.finalizeAppointmentData = data
					} else {
						this.changeStatus( data.id, "finished", data.callback )
					}
				} else {
					data.callback( false )
				}
			},
			async finishWithStocks( sliders, modalCallback ) {
				try {
					const app = this.finalizeAppointmentData.appointment
					let stocksPatch = []
					let historyPost = []
					Object.keys(sliders).forEach( id => {
						const stock = this.$store.state.stocks.find( st => st.id == id )
						const current = sliders[id].map( ( value, i ) => ( stock.current[i] || 100 ) - value )
						const opened_stocks = Math.max( 0, current.filter( v => v < 100 ).length - stock.current.length )
						stocksPatch.push({
							id: parseInt(id),
							current: current.filter( v => v > 0 ),
							"total_used[+]": current.filter( v => v <= 0 ).length,
							last_consumed: current.reduce( ( a, b, i ) => a + Math.max( 0, ( stock.current[i] || 100 ) - b ), 0 ),
							"stocks[-]": opened_stocks
						})
						if ( app.user ) {
							historyPost.push({
								userid: app.user.id,
								stockid: id,
								date: dayjs().toISOString()
							})
						}
					})
					await this.changeStatus( this.finalizeAppointmentData.id, "finished", modalCallback, this.finalizeAppointmentData.callback )
					if ( app.user ) {
						await this.$api.stockshistory.post( historyPost )
					}
					// TODO : prevent "stock modified" notification
					await this.$api.stocks.patch( stocksPatch )
					setTimeout( () => {
						this.finalizeAppointmentData = null
					}, 750 )
				} catch ( error ) {
					console.log( "finishWithStocks patch error", error )
				//	this.finalizeAppointmentData.callback( false, error.response.data.error )
					this.finalizeAppointmentData.callback( false )
					modalCallback( false, error.response.data.error )
				}
			},
			clientAbsent() {
				this.$api.appointment.patch( this.absentAppointment.id, { status: 'absent' } ).then( response => {
					this.absentAppointment.callback( true )
					setTimeout( () => {
						this.absentAppointment = null
					}, 750 )
				}).catch( error => {
					console.log( error )
					this.absentAppointment.callback( false, error.response.data.error || error )
				})
			},
			changeStatus( id, status, callback, callback2 ) {
				return this.$api.appointment.patch( id, { status: status } ).then( async response => {
					let app = this.appointments.find( a => a.id == id )
					if ( status == "finished" && app && app.userid && app.userid > 0 ) {
						await this.$api.salonsuser.patch( app.userid, { "loyalty[+]": 1 } )
					}
					callback( true )
					if ( callback2 ) {
						callback2( true )
					}
				}).catch( error => {
					console.log( "changeStatus patch error", error )
					callback( false, error.response.data.error )
					if ( callback2 ) {
						callback2( false )
					}
				})
			},
			/*
			deleteReview(id) {
				this.$api.review.delete(id).then( response => {
					this.deleteReviewModalCallback( true )
					this.deleteReviewModalCallback = null
				}).catch( error => {
					console.log( error )
					this.deleteReviewModalCallback( false, error.response.data.error )
				})
			},
			*/
			imageResize( imgBase ) {
				return new Promise( ( resolve, reject ) => {
					let img = document.createElement( "img" )
					img.src = imgBase.src
					const MAX_WIDTH = 1280
					const MAX_HEIGHT = 800
					let width = img.width
					let height = img.height

					if ( width > height ) {
						if ( width > MAX_WIDTH ) {
							height *= MAX_WIDTH / width
							width = MAX_WIDTH
						}
					} else {
						if (height > MAX_HEIGHT ) {
							width *= MAX_HEIGHT / height
							height = MAX_HEIGHT
						}
					}
					let canvas = document.createElement( "canvas" )
					canvas.width = width
					canvas.height = height
					let ctx = canvas.getContext( "2d" )
					ctx.drawImage( img, 0, 0, width, height )
					canvas.toBlob( blob => {
						resolve( blob )
					})
				})
			},
			async deleteSingleProduct(callback) {
				console.log('licorne')
				this.$api.product.patch( this.products[0].id,
				{ title: '', price: '', text: '', image: '', alt: '', order: 0 }
				, { "{return}": [ "image" ] } ).then( response => {
					callback( true )
					this.products[0].image = ''
					setTimeout( () => {
						this.editSingleProduct = false
						this.editSingleProductImage = ""
						this.missingProductTitle = false
						this.missingProductPrice = false
						this.missingProductImage = false
					}, 750 )
				}).catch(error => {
					console.log(error)
					callback(false, error.response.data.error)
				})
				console.log('licorne2')
			},
			async saveSingleProduct( callback ) {
				let checkFailed = false
				if ( !this.products[0].title ) {
					this.missingProductTitle = true
					checkFailed = true
				}
				if ( ( !this.editSingleProductImage || this.editSingleProductImage.length == 0 ) && !this.products[0].image ) {
					this.missingProductImage = true
					checkFailed = true
				}
				if ( checkFailed ) {
					callback( false )
				} else {
					/*
					if ( this.editSingleProductImage && this.editSingleProductImage.length > 0 ) {
						const file = this.$refs.singleProductBrowse.files[0]
						const toBase64 = () => new Promise( ( resolve, reject ) => {
							const reader = new FileReader()
							reader.onload = () => resolve( reader.result )
							reader.onerror = error => console.log(error)
							reader.readAsDataURL( file )
						})

						this.products[0].image = await toBase64()
					}
					*/
					let formData = new FormData()
					if ( this.editSingleProductImage && this.editSingleProductImage.length > 0 ) {
						formData.append( "image", this.$refs.singleProductBrowse.files[0] )
					}
					formData.append( "data", JSON.stringify( { ...this.products[0], image: "" } ) )
					console.log( formData )
					if ( this.products[0].id && this.products[0].id > 0 ) {
						this.$api.product.patch( this.products[0].id, formData/*this.products[0]*/, { "{return}": [ "image" ] } ).then( response => {
							callback( true )
							this.products[0].image = response.data[0].image && process.env.VUE_APP_API_ROOT + response.data[0].image
							setTimeout( () => {
								this.editSingleProduct = false
								this.editSingleProductImage = ""
								this.missingProductTitle = false
								this.missingProductPrice = false
								this.missingProductImage = false
							}, 750 )
						}).catch(error => {
							console.log(error)
							callback(false, error.response.data.error)
						})
					} else {
						delete this.products[0].id
						this.$api.products.post( formData/*this.products[0]*/ ).then( response => {
							callback( true )
							this.products[0].image = response.data[0].image && process.env.VUE_APP_API_ROOT + response.data[0].image
							setTimeout( () => {
								this.editSingleProduct = false
								this.editSingleProductImage = ""
								this.missingProductTitle = false
								this.missingProductPrice = false
								this.missingProductImage = false
							}, 750 )
						}).catch(error => {
							console.log(error)
							callback(false, error.response.data.error)
						})
					}
				}
			},
			cancelSaveSingleProduct() {
				this.editSingleProduct = false
				this.editSingleProductImage = ""
				this.missingProductTitle = false
				this.missingProductPrice = false
				this.missingProductImage = false

				// Get previous data back
				this.$api.products.get({ "{max}": 1, "{sort}": "desc:id" }).then( response => {
					this.products = response.data
					this.products.forEach( product => { product.image = product.image && process.env.VUE_APP_API_ROOT + product.image })
				}).catch( error => console.log(error) )
			},
			updateStats() {
				var now = new Date()
				var month = new Date()
				month.setSeconds( 0 )
				month.setMinutes( 0 )
				month.setHours( 0 )
				month.setDate( 1 )
				var year = new Date( month )
				year.setMonth( 0 )

				this.$api.salonsusers.count({ "!registration": '', "unregistration": '' }).then( response => {
					this.infos.totalClients = response.data[0].count
				}).catch(error => console.log(error))

				this.$api.salonsusers.count({ "!registration": '', "unregistration": '', "!userid": '' }).then( response => {
					this.infos.totalAppClients = response.data[0].count
				}).catch(error => console.log(error))
				

				this.$api.appointments.count({ "date[gte]": dayjs(month).format("YYYY-MM-DD"), "date[lte]": dayjs(now).format("YYYY-MM-DD 23:59:59"), "status[~*]": "finished" }).then( response => {
					this.infos.appointments.month = response.data[0].count
				}).catch(error => console.log(error))

				this.$api.appointments.count({ "date[gte]": dayjs(year).format("YYYY-MM-DD"), "date[lte]": dayjs(now).format("YYYY-MM-DD 23:59:59"), "status[~*]": "finished" }).then( response => {
					this.infos.appointments.year = response.data[0].count
				}).catch(error => console.log(error))
			},
			addAppointment( app, canpush ) {
				var duration = 0
				if ( app.timeline ) {
					for ( var j = 0; j < app.timeline.length; j++ ) {
						duration += app.timeline[j]
					}
				}
				app.worker = this.$store.getters.getWorker( app.workers[0] )
				app.startHour = dayjs( app.date ).format( "HH:mm" )
				app.endHour = dayjs( new Date( app.date ).addMinutes( duration ) ).format( "HH:mm" )
				app.duration = duration + " min"

				if ( canpush ) {
					this.appointments.push( app )
					this.appointments.sort( ( a, b ) => dayjs(a.date).diff(b.date) )
				}

				var thiz = this
				this.$nextTick( async function () { // Wait for next tick (i.e. wait for appointment Component to be created) to fetch user
					var comp = thiz.$refs[ "app_" + app.id ]
					if ( comp ) {
						comp = comp[0]
					}
					if ( comp ) {
						comp.$el.classList.add( "new-appointment" )
						setTimeout( () => {
							comp.$el.classList.remove( "new-appointment" )
						}, 500 )
						var ret = await this.$store.getters.getUser( app.userid ).catch(error => console.log(error))
						if ( ret ) {
							app.user = ret.data[0]
							comp.$forceUpdate()
						}
					}
					this.$forceUpdate()
				})
			},
			async updateAppointments() {
				this.appointments = []
				var todayDate = new Date()
			//	var todayDate = new Date("Tue, 19 May 2020 16:00:55 GMT")
				todayDate.setHours( 0 )
				todayDate.setMinutes( 0 )
				todayDate.setSeconds( 0 )
				var today = dayjs( todayDate )
				var tonight = dayjs( todayDate.addDays(1) )
				this.infos.appointments.day.total = 0
				await this.$api.appointments.get({ "{max}": 15, "date[gte]": today.format("YYYY-MM-DD"), "date[lt]": tonight.format("YYYY-MM-DD"), "{sort}": "asc:date", "{or}": { "status": null, "status[in]": [ "", "ongoing" ] } }).then( async response => {
					for ( var i = 0; i < response.data.length; i++ ) {
						this.addAppointment( response.data[i], true )
					}
					this.infos.appointments.day.total += response.data.length
				}).catch(error => console.log(error))
				await this.$api.appointments.get({ "{max}": 10, "date[gte]": today.format("YYYY-MM-DD"), "date[lt]": tonight.format("YYYY-MM-DD"), "{sort}": "asc:date", "{or}": { "status[in]": [ "finished", "canceled", "absent" ] } }).then( async response => {
					var validated = 0
					for ( var i = 0; i < response.data.length; i++ ) {
						var app = response.data[i]
						if ( app.status && app.status.indexOf( "finished" ) >= 0 ) {
							validated++
						}
						this.addAppointment( app, true )
					}
					this.infos.appointments.day.validated = validated
					this.infos.appointments.day.total += response.data.length
				}).catch(error => console.log(error))
				this.$forceUpdate()
			},
			update() {
				this.updateStats()
				this.updateAppointments()

				this.$api.reviews.get({ "{max}": 2, "{sort}": "desc:id" }).then( response => {
					this.setCurrentReview( response.data[0] )
					this.noReviewPrev = !response.data[1]
				}).catch( error => console.log(error) )

				if ( true /* single-product-mode */ ) {
					this.$api.products.get({ "{max}": 1, "{sort}": "desc:id" }).then( response => {
						this.products = response.data
						this.products.forEach( product => { product.image = product.image && process.env.VUE_APP_API_ROOT + product.image })
					}).catch( error => console.log(error) )
				}
			}
		},
		created() {
			this.update()

			this.$api.salonsusers.watch( action => {
				if ( action.method == "POST" ) {
					this.infos.totalClients++
				} else if ( action.method == "DELETE" ) {
					this.infos.totalClients--
				}
			})

			this.$api.appointments.watch( action => {
				if ( action.method == "POST" ) {
						var todayDate = new Date()
						todayDate.setHours( 0 )
						todayDate.setMinutes( 0 )
						todayDate.setSeconds( 0 )
						var today = dayjs( todayDate )
						var tonight = dayjs( todayDate.addDays(1) )
						var date = dayjs(action.data[0].date)
						if (date.isAfter(today) && date.isBefore(tonight)) {
							this.addAppointment( action.data[0], true )
							this.infos.appointments.day.total++
						}
						/*
						this.infos.appointments.month++
						this.infos.appointments.year++
						*/
				} else if ( action.method == "DELETE" ) {
					var id = action.data[0].id
					for (var i = 0; i < this.appointments.length; i++) {
						if ( this.appointments[i].id == id ) {
							this.infos.appointments.day.total--
							if ( this.appointments[i].status && this.appointments[i].status.indexOf( "finished" ) >= 0 ) {
								this.infos.appointments.day.validated--
								this.infos.appointments.month--
								this.infos.appointments.year--
							}
							this.appointments.splice( i, 1 )
							break
						}
					}
				} else if ( action.method == "PATCH") {
					var id = action.data[0].id
					for (var i = 0; i < this.appointments.length; i++) {
						if (this.appointments[i].id == id) {
							if ( action.data[0].status != undefined && this.appointments[i].status != null ) {
								if( this.appointments[i].status.indexOf( "finished" ) >= 0 && action.data[0].status.indexOf( "finished" ) < 0 ) {
									this.infos.appointments.day.validated--
								} else if( this.appointments[i].status.indexOf( "finished" ) < 0 && action.data[0].status.indexOf( "finished" ) >= 0 ) {
									this.infos.appointments.day.validated++
								}
							}
							Object.assign(this.appointments[i], action.data[0])
							this.addAppointment( this.appointments[i], false )
							break
						}
					}
				}
			})
	
			this.$api.reviews.watch( action => {
				if ( action.method == "POST" ) {
					this.setCurrentReview( action.data[0] )
				} else if ( action.method == "PATCH" ) {
					if ( this.review && action.data[0].id == this.review.id ) {
						Object.assign( this.review, action.data[0] )
						this.setCurrentReview( this.review )
					}
				} else if ( action.method == "DELETE" ) {
					if ( this.review && action.data[0].id == this.review.id ) {
						this.$api.reviews.get({ "{max}": 1, "{sort}": "desc:id" }).then( response => {
							this.setCurrentReview( response.data[0] )
						}).catch( error => console.log(error) )
					}
				}
			})

			if ( true /* single-product-mode */ ) {
				this.$api.products.watch( action => {
					if ( action.method == "POST" ) {
						this.products[0] = action.data[0]
						this.products[0].image = this.products[0].image && process.env.VUE_APP_API_ROOT + this.products[0].image
					} else if ( action.method == "PATCH" ) {
						if ( action.data[0].id == this.products[0].id ) {
							Object.assign( this.products[0], action.data[0] )
							if ( action.data[0].image ) {
								this.products[0].image = action.data[0].image && process.env.VUE_APP_API_ROOT + action.data[0].image
							}
						}
					} else if ( action.method == "DELETE" ) {
						if ( action.data[0].id == this.products[0].id ) {
							this.products = [{}]
						}
					}
				})
			}
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/index.scss"/>
