<template>
	<Modal>
		<template #header>
			<h1 class="title-search">{{ $t("titles.productused") }}</h1>
		</template>
		<template #content>
			<div id="stocks-cont">
				<div class="d-flex d-justify-content-center stock-entry" v-for="( stock, idx ) in stocks" :key="stock.id">
					<StockButton :id="'stock-' + stock.id" :stock="stock" @remove="stocks.splice( stocks.indexOf( stock ), 1 )" />
					<div class="row stocks-slider-ctnr" v-for="i in sliders[stock.id].length" :key="i">
						<div class="stocks-text-xpln">{{ $t('stocks.usedquantity') }}</div>
						<div class="col-10">
							<VueSlider tooltip="none" v-model="sliders[stock.id][i - 1]" :data="[0,10,20,30,40,50,60,70,80,90,100]" :marks="true"/>
						</div>
						<div class="col-md-2">
							<div class="bottle-ctnr">
								<div class="bottle">
									<VueSlider tooltip="none" direction="btt" class="vertical" :dotOptions="{max: stock.max[i - 1]}" :value="( stock.current || [] )[i - 1] - sliders[stock.id][i - 1]" @change="$set( sliders[stock.id], i - 1, ( stock.current || [] )[i - 1] - $event )" :data="[0,10,20,30,40,50,60,70,80,90,100]" :marks="true"/>
									<div class="value">{{ Math.max( 0, ( stock.current || [] )[i - 1] - sliders[stock.id][i - 1] ) }}</div>
									<div class="bottle-mask"/>
								</div>
								<div class="bottle-text-xpln">{{ $t('common.stockleft') }}</div>
							</div>
							<!--div class="still">{{ $t("common.left") }}</div-->
						</div>
					</div>
					<div class="remaining-stocks-hint"><div>{{ $t('stocks.remainingstocks') }} {{ stock.stocks }}</div></div>
					<button class="btn plus" v-show="( stock.current || [] ).length < 3" @click="( stock.current || [] ).push(100); $set( sliders[stock.id], sliders[stock.id].length, 0 )">
						<i class="fa fa-plus"></i>
					</button>
				</div>
				<StockButton :force-show="stocks.length === 0" @selected="add($event)" :hide="stocks.map(s => s.id)" />
			</div>
		</template>
		<template #buttons>
			<StatusButton type="primary" @click="$emit('confirm', sliders, $event)" alert="modal">{{$t("modals.finalize.finalize")}}</StatusButton>
			<button class="btn btn-gray" @click="$emit('close')" type="button">{{$t("common.cancel")}}</button>
		</template>
	</Modal>
</template>


<script>
	import VueSlider from 'vue-slider-component'
	import Modal from "../components/Modal.vue"
	import StockButton from '../components/StockButton.vue'
	import StatusButton from '../components/StatusButton.vue'

	export default {
		components: {
			VueSlider,
			Modal,
			StockButton,
			StatusButton
		},
		props: [
		],
		data() {
			return {
				stocks: [],
				sliders: []
			}
		},
		methods: {
			add( stock ) {
				let last_consumed = stock.last_consumed
				this.$set( this.sliders, stock.id, [] )
				for ( let i = 0; i < ( stock.current || [] ).length; i++ ) {
					/*
					if ( last_consumed && last_consumed > 0 ) {
						let val = Math.min( last_consumed, stock.current[i] )
						last_consumed -= val
						this.$set( this.sliders[stock.id], i, val )
					} else {
					*/
						this.$set( this.sliders[stock.id], i, 0 )
				//	}
				}
				stock.max = ( stock.current || [] ).slice()
				this.stocks.push( stock )
				this.stocks.sort( ( a, b ) => a.priority - b.priority )
			}
		},
		created() {
		},
		beforeDestroy() {
		}
	}
</script>


<style scoped lang="scss" src="../css/modals/finalizeappointment.scss"/>
